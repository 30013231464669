var angularModule = angular.module('t2g.booking.services.modal', []);

module.exports = angularModule;

angularModule.factory('ModalService', function ($animate,
                                         $compile,
                                         $controller,
                                         $document,
                                         $injector,
                                         $q,
                                         $rootScope,
                                         $templateRequest) {
  /**
   * @param {String} path
   * @return {Promise}
   */
  var getTemplate = function (path) {
    return $templateRequest(path);
  };

  /**
   * @param {Object} resolver
   * @return {Promise}
   */
  var getResolveData = function (resolver) {
    var deferred = $q.defer();
    var resolveValues = {};
    var resolveItems = [];

    angular.forEach(resolver, function (resolve) {
      if (angular.isFunction(resolve) || angular.isArray(resolve)) {
        resolveItems.push($q.resolve($injector.invoke(resolve)));
      } else if (angular.isString(resolve)) {
        resolveItems.push($q.resolve($injector.get(resolve)));
      } else {
        resolveItems.push($q.resolve(resolve));
      }
    });

    $q.all(resolveItems).then(function (resolves) {
      var index = 0;

      angular.forEach(resolver, function (promise, resolveName) {
        resolveValues[resolveName] = resolves[index];
        index++;
      });

      deferred.resolve(resolveValues);
    });

    return deferred.promise;
  };


  return {
    open: function (options) {
      var promises = [getTemplate(options.template)];
      var controller = null;
      var controllerLocals = {
        $scope: $rootScope.$new()
      };
      var bodyElement = $document.find('body');
      var closeDefer = $q.defer();
      var modalInstance = {
        closed: closeDefer.promise
      };

      // resolve promises
      if (options.resolve) {
        promises.push(getResolveData(options.resolve));
      }

      // after resolving promises show modal
      $q
        .all(promises)
        .then(function (response) {
          // create controller instance

          // delete all $promise to avoid merge max call stack error
          for (var promise in response[1]) {
            response[1][promise].$promise = null;
          }

          angular.merge(controllerLocals, response[1]);
          controllerLocals.modalInstance = modalInstance;
          controller = $controller(options.controller, controllerLocals);

          // inject html
          var modalElement = angular.element(response[0]);

          $animate.enter($compile(modalElement)(controllerLocals.$scope), bodyElement)
            .then(function () {
              $animate.addClass(bodyElement, 'show-modal');
            });

          modalInstance.close = function (response) {
            $animate.removeClass(bodyElement, 'show-modal').then(function () {
              modalElement.remove();
              controllerLocals.$scope.$destroy();
              controller = null;
              closeDefer.resolve(response);
            });
          };
        });

      return modalInstance;
    }
  }
});
