function getParameterByName(name, url) {
  if (!url) url = window.location.href;
  name = name.replace(/[\[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

var module = angular.module('t2g.booking.appointment', [
  'ngRoute',
  'ngAnimate',
  'ngMessages',
  'ngRoute',
  'ngSanitize',
  'ui.router',
  'ui.mask',
  'ngStorage',
  'gettext',
  require('./modules/filter').name,
  require('./modules/directive').name,
  require('./modules/service').name
]);

var apiBase = 'https://app.termin2go.com/api/v2';

module.value('apiBase', apiBase);

module.run(function ($rootScope,
                     $window,
                     $location,
                     $transitions,
                     gettextCatalog) {

  var lang = getParameterByName('lang') || 'de';

  if (['de', 'en'].indexOf(lang) === -1) {
    lang = 'de';
  }

  $transitions.onSuccess({}, function (transition) {
    var to = transition.to();
    var step = to.params && !isNaN(to.params.step) ? to.params.step : 'unknown';

    if (window.parent) {
      parent.postMessage({type: 'pageView', step: step + 1, viewName: to.name, url: to.url}, '*');
    }
  });

  gettextCatalog.setCurrentLanguage(lang);
  gettextCatalog.loadRemote(templateUrl + '/i18n/' + lang + '-2.27.0.json');
  moment.locale(lang);
});

module.controller('ServiceCtrl', require('./modules/steps/service/ServiceCtrl'));
module.controller('ServiceDetailModalCtrl', require('./modules/steps/service/ServiceDetailModalCtrl'));
module.controller('AppointmentCtrl', require('./modules/steps/appointment/AppointmentCtrl'));
module.controller('ContactCtrl', require('./modules/steps/contact/ContactCtrl'));
module.controller('PersonModalCtrl', require('./modules/steps/contact/PersonModalCtrl'));
module.controller('TermsModalCtrl', require('./modules/steps/contact/TermsModalCtrl'));
module.controller('PaymentCtrl', require('./modules/steps/payment/PaymentCtrl'));
module.controller('SuccessCtrl', require('./modules/steps/success/SuccessCtrl'));

module.config(function ($stateProvider, $urlRouterProvider) {
  var preselectedService = getParameterByName('service');
  var groupFiler = getParameterByName('group') || '';

  groupFiler = groupFiler.replace(/[^A-Fa-f0-9,]/g, '');

  if (!preselectedService) {
    $urlRouterProvider.otherwise('/services');
  } else {
    $urlRouterProvider.otherwise('/appointments/');
  }

  $stateProvider
    .state('booking', {
      url: '/:officeSlug',
      abstract: true,
      template: '<div class="inner-view-container" ui-view></div>'
    })
    .state('booking.services', {
      url: '/services?channel&service&redirected&auto',
      templateUrl: templateUrl + '/views/ServicesView.html?v=2.27.0',
      controller: 'ServiceCtrl as ServiceCtrl',
      params: {
        step: 0
      },
      resolve: {
        officeResolve: [
          'OfficeStorage',
          '$stateParams',
          function (OfficeStorage, $stateParams) {
            return OfficeStorage
              .get(true, $stateParams.officeSlug)
              .then(function (office) {
                const groupsFilter = groupFiler.length ? groupFiler.split(',') : [];
                office.serviceGroups = office.serviceGroups.filter(function (serviceGroup) {
                  console.log(groupsFilter);
                  if (groupsFilter && groupsFilter.length && !groupsFilter.includes(serviceGroup._id)) {
                    return false;
                  }

                  serviceGroup.services = serviceGroup.services.filter(function (service) {
                    return !service.deepLink;
                  });
                  return serviceGroup.services.length > 0;
                });
                return office;
              });
          }
        ]
      }
    })
    .state('booking.servicesOld', {
      url: '/dienstleistung?channel&service&redirected&auto',
      templateUrl: templateUrl + '/views/ServicesView.html?v=2.27.0',
      controller: 'ServiceCtrl as ServiceCtrl',
      params: {
        step: 0
      },
      resolve: {
        officeResolve: [
          'OfficeStorage',
          '$stateParams',
          function (OfficeStorage, $stateParams) {
            return OfficeStorage.get(true, $stateParams.officeSlug);
          }
        ]
      }
    })
    .state('booking.appointments', {
      url: '/appointments/:start?channel&redirected',
      templateUrl: templateUrl + '/views/AppointmentView.html?v=2.27.0',
      controller: 'AppointmentCtrl as AppointmentCtrl',
      params: {
        step: 1,
        start: null
      },
      resolve: {
        data: function (OfficeStorage, $stateParams, TimeSlotStorage, MediaQueryService, SessionService) {
          return OfficeStorage
            .get(false, $stateParams.officeSlug)
            .then(function (office) {
              var serviceQueryParam = getParameterByName('service') || '';
              var preselectedService;
              var booking = SessionService.getBooking();
              var temp = SessionService.getTemp();
              var selectedSlotStart = _.get(booking, 'slot.combinations[0].start');
              var isMobile = MediaQueryService.isMobile();
              var startDate;
              var days = [];
              var range = isMobile ? 5 : 7;

              serviceQueryParam = serviceQueryParam.replace(/[^A-Fa-f0-9]/g, '');

              _.forEach(office.serviceGroups, function (serviceGroup) {
                preselectedService = _.find(serviceGroup.services, {_id: serviceQueryParam});

                if (preselectedService) {
                  return false;
                }
              });

              if (preselectedService) {
                SessionService.addService(preselectedService);
              }

              if ($stateParams.start) {
                startDate = moment($stateParams.start);
              } else if (selectedSlotStart) {
                if (range === 5) {
                  startDate = moment(selectedSlotStart);
                } else {
                  startDate = moment(selectedSlotStart).day(1);
                }
              } else {
                startDate = moment();
              }

              for (var i = 0; i < range; i++) {
                var date = moment(startDate).add(i, 'days');
                days.push({
                  dateKey: date.format('YYYY-MM-DD'),
                  date: date
                });
              }

              return TimeSlotStorage
                .get(startDate, serviceQueryParam, $stateParams.officeSlug)
                .then(function (response) {
                  var res = {
                    days: days
                  };

                  if (response.timeSlots) {
                    res.combinations = response.timeSlots;
                  } else {
                    res.nextAvailableSlot = response.nextAvailableSlot;
                  }

                  return {
                    appointmentResolve: res,
                    officeResolve: office
                  };
                });
            })
        }
      }
    })
    .state('booking.contact', {
      url: '/contact?channel&redirected',
      templateUrl: templateUrl + '/views/ContactView2.html?v=2.27.0',
      controller: 'ContactCtrl as ContactCtrl',
      params: {
        step: 2
      },
      resolve: {
        officeResolve: [
          'OfficeStorage',
          '$stateParams',
          function (OfficeStorage, $stateParams) {
            return OfficeStorage.get(false, $stateParams.officeSlug);
          }
        ]
      }
    })
    .state('booking.success', {
      url: '/success',
      templateUrl: templateUrl + '/views/SuccessView.html?v=2.27.0',
      controller: 'SuccessCtrl as SuccessCtrl',
      params: {
        step: 3
      },
      resolve: {
        officeResolve: [
          'OfficeStorage',
          '$stateParams',
          function (OfficeStorage, $stateParams) {
            return OfficeStorage.get(false, $stateParams.officeSlug);
          }
        ]
      }
    })
    .state('booking.payment', {
      url: '/payment',
      templateUrl: templateUrl + '/views/PaymentView.html?v=2.27.0',
      controller: 'PaymentCtrl as PaymentCtrl',
      params: {
        step: 4
      },
      resolve: {
        officeResolve: [
          'OfficeStorage',
          '$stateParams',
          function (OfficeStorage, $stateParams) {
            return OfficeStorage.get(false, $stateParams.officeSlug);
          }
        ],
        secretResolve: [
          'SessionService',
          function (SessionService) {
            return SessionService.getPayment().stripeSecret;
          }
        ]
      }
    });
});

var MainCtrl = function ($transitions,
                         $rootScope,
                         $scope,
                         $sessionStorage,
                         $state,
                         $stateParams,
                         SessionService,
                         $location) {
  var self = this;
  this.preselectedService = getParameterByName('service');
  this.logo = getParameterByName('logo');
  this.scope_ = $scope;
  this.SessionService_ = SessionService;
  this._temp = this.SessionService_.getTemp();
  this.state_ = $state;
  this.rootScope_ = $rootScope;
  this.overallPrice = 0;
  this.currency = '';
  $scope.currentStep = $stateParams.step;
  $scope.hideSteps = false;
  $scope.hideBottomBar = false;
  $scope.showLoadingIndicator = false;
  $scope.forwardButtonDisabled = true;
  $scope.backButtonDisabled = false;
  $scope.needScrollingClass = !!$location.search().redirect;
  $scope.logo = undefined;

  $scope.steps = [
    {
      state: 'booking.services',
      active: false,
      done: false
    },
    {
      state: 'booking.appointments',
      active: false,
      done: false
    },
    {
      state: 'booking.contact',
      active: false,
      done: false
    }
  ];

  // change states of steps
  $scope.$watch(function () {
    return angular.toJson($sessionStorage);
  }, function () {
    self.checkStep();
  });

  // show loading indicator
  $transitions.onStart({}, function (transition) {
    var toState = transition.to();
    var fromState = transition.from();

    if (toState.resolve) {
      $scope.showLoadingIndicator = true;
    }

    // if history back on success view
    if (fromState.name === 'success' && $sessionStorage.booking.slot) {
      event.preventDefault();
      $scope.showLoadingIndicator = false;
    } else {
      $scope.hideSteps = false;
      $scope.hideBottomBar = false;
    }
  });

  $transitions.onSuccess({}, function (transition) {
    var toState = transition.to();

    if (toState.resolve) {
      $scope.showLoadingIndicator = false;
    }

    $scope.currentStep = toState.params.step;
    self.checkStep();
  });

  $transitions.onError({}, function (transition) {
    console.error(transition.error());
  });

  $scope.$on('contact-form-valid', function (event, data) {
    // check if an appointment was picked
    if (data) {
      self.scope_.steps[2].done = true;
      $scope.bookingButtonDisabled = false;
    } else {
      self.scope_.steps[2].done = false;
      $scope.bookingButtonDisabled = true;
    }
  });

  $scope.$on('contact-form-loading', function (event, data) {
    // check if an appointment was picked
    $scope.showLoadingIndicator = data;
  });

  $scope.$on('contact-form-send', function (event, data) {
    $scope.bookingButtonDisabled = data;
  });

  $scope.$on('price-changed', function (event, data) {
    self.overallPrice = data.price;
    self.currency = data.currency;
  });

  $scope.$on('slot-selected', function () {
    self.toToNextState();
  });

  $rootScope.$on('office:loaded', (event, office) => {
    const showLogoInStandalone = _.get(office, 'settings.widget.appointment.showLogoInStandalone');
    const logo = _.get(office, 'profile.logo.m')
    const showLogo = inIframe && !inIframe();

    if (showLogoInStandalone && showLogo && logo) {
      $scope.logo = logo;
    }
  });

  this.init();
};

MainCtrl.prototype.init = function () {
  this.scope_.booking = this.SessionService_.getBooking();
};

MainCtrl.prototype.toToNextState = function () {
  if (this.scope_.currentStep < 2) {
    this.state_.go(this.scope_.steps[this.scope_.currentStep + 1].state);
  }
};

MainCtrl.prototype.toToPrevState = function () {
  if (this.scope_.currentStep > 0) {
    this.state_.go(this.scope_.steps[this.scope_.currentStep - 1].state);
  }
};

MainCtrl.prototype.checkStep = function () {
  var services = this._temp.services;
  var booking = this.SessionService_.getBooking();

  // deactivate steps
  for (var i = 0, stepsLen = this.scope_.steps.length; i < stepsLen; i++) {
    if (i <= this.scope_.currentStep) {
      this.scope_.steps[i].active = true;
    } else {
      this.scope_.steps[i].active = false;
    }
  }

  // check if some services are picked
  if (angular.isArray(services) && services.length) {
    this.scope_.steps[0].done = true;

    if (this.scope_.currentStep === 0) {
      this.scope_.forwardButtonDisabled = false;
    }
  } else {
    this.scope_.steps[0].done = false;

    if (this.scope_.currentStep === 0) {
      this.scope_.forwardButtonDisabled = true;
    }
  }

  // check if an appointment was picked
  if (booking.slot) {
    this.scope_.steps[1].done = true;

    if (this.scope_.currentStep === 1) {
      this.scope_.forwardButtonDisabled = false;
    }

  } else {
    this.scope_.steps[1].done = false;

    if (this.scope_.currentStep === 1) {
      this.scope_.forwardButtonDisabled = true;
    }
  }

  // hide steps and bottom bar on success view
  if (this.scope_.currentStep === 3) {
    this.scope_.hideSteps = true;
    this.scope_.hideBottomBar = true;
  }
  this.scope_.backButtonDisabled = (this.preselectedService && this.scope_.currentStep === 1) || this.scope_.currentStep === 4;
  this.scope_.showCheckoutButton = booking.paymentMethod && booking.paymentMethod.length && booking.paymentMethod !== 'on-site' && this.overallPrice > 0;
  this.scope_.showPayButton = this.scope_.currentStep === 4;
};


MainCtrl.prototype.submit = function () {
  this.rootScope_.$broadcast('onSubmitBtnClick')
};

MainCtrl.prototype.checkout = function () {
  this.rootScope_.$broadcast('onCheckoutBtnClick')
}

MainCtrl.prototype.pay = function () {
  this.rootScope_.$broadcast('onPayBtnClick')
}

module.controller('MainCtrl', MainCtrl);

Waves.init();
Waves.attach('.waves-effect');
