var SuccessCtrl = function ($state,
                            $stateParams,
                            SessionService,
                            officeResolve) {

  this._state = $state;
  this._stateParams = $stateParams;
  this.office = officeResolve;

  SessionService.resetBooking();
};

SuccessCtrl.prototype.goToStart = function () {
  if (this._stateParams.type === 'walkIn') {
    this._state.go('booking.walkInContact');
  } else {
    this._state.go('booking.services');
  }
};

module.exports = SuccessCtrl;
