'use strict';

var angularModule = angular.module('t2g.booking.service', [
    require('./APIService').name,
    require('./MediaQueryService').name,
    require('./ModalService').name,
    require('./SessionService').name
]);

module.exports = angularModule;
