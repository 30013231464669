'use strict';

var angularModule = angular.module('t2g.booking.directive', [
    require('./collapseDirective').name,
    require('./collapseGroupDirective').name,
    require('./collapseItemDirective').name,
    require('./dateMaskDirective').name,
    require('./dropdownControlDirective').name,
    require('./expectedValueDirective').name,
    require('./focusFirstErrorDirective').name,
    require('./numberInputDirective').name,
    require('./numberRangeDirective').name,
    require('./outsideClickDirective').name,
    require('./streetCheckDirective').name,
    require('./scrollDirective').name
]);

module.exports = angularModule;
