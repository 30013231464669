var PersonModalCtrl = function ($scope,
                                officeResolve,
                                personResolve,
                                modalInstance) {
  $scope.currentPerson = personResolve;

  if (!$scope.currentPerson.firstname) {
    $scope.currentPerson = {
      sameHousehold: true
    }
  }

  this._scope = $scope;
  this._scope.formSubmitted = false;
  this._modalInstance = modalInstance;
  this.office = officeResolve;
};

PersonModalCtrl.prototype.close = function (person) {
  if (person) {
    this._modalInstance.close(person);
  } else {
    this._modalInstance.close();
  }
};

PersonModalCtrl.prototype.showAddressFields = function () {
  var customerAddressSettings = _.get(this.office, 'settings.widget.appointment.customerAddress');
  return customerAddressSettings && ['optional', 'required'].indexOf(customerAddressSettings) > -1;
};

PersonModalCtrl.prototype.addressFieldsRequired = function () {
  var customerAddressSettings = _.get(this.office, 'settings.widget.appointment.customerAddress');
  return customerAddressSettings && customerAddressSettings === 'required';
};
PersonModalCtrl.prototype.save = function () {
  this._scope.formSubmitted = true;

  if (this._scope.PersonModalForm.$valid) {
    this.close(this._scope.currentPerson)
  }
}

module.exports = PersonModalCtrl;
