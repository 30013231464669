var TermsModalCtrl = function ($sce,
                               $scope,
                               path,
                               title,
                               officeResolve,
                               modalInstance) {

  this.sce_ = $sce;
  this.scope_ = $scope;
  this._path = path;
  this.title = title;
  this.scope_.office = officeResolve;
  this.modalInstance_ = modalInstance;
};


/**
 *
 */
TermsModalCtrl.prototype.close = function () {
  this.modalInstance_.close();
};


/**
 *
 * @returns {String}
 */
TermsModalCtrl.prototype.getTerms = function () {
  return this.sce_.trustAsHtml(_.get(this.scope_.office, this._path));
};

module.exports = TermsModalCtrl;
