var angularModule = angular.module('t2g.booking.directive.numberRange', []);
module.exports = angularModule;

angularModule.directive('numberRange', [function() {
  return {
    require: 'ngModel',
    link: function(scope, elem, attrs, ctrl) {
      if (!ctrl) return;
      var range = attrs.numberRange.split(',');
      ctrl.$validators.numberRange = function(value) {
        return parseInt(value, 10) >= parseInt(range[0], 10) && parseInt(value, 10) <= parseInt(range[1], 10);
      };
    }
  };
}]);
