var angularModule = angular.module('t2g.booking.directive.scroll', []);
var SHADOW_TOP_CLASS = 'shadow-top';
var SHADOW_BOTTOM_CLASS = 'shadow-bottom';
var MARGIN = 10;

module.exports = angularModule;

var checkScrollState = function () {
  var scrollTop = window.scrollY || document.documentElement.scrollTop;
  var currentScrollHeight = scrollTop + window.innerHeight;
  var state = {
    top: (scrollTop < MARGIN),
    bottom: (currentScrollHeight > document.documentElement.scrollHeight - MARGIN)
  };

  return state;
};

var updateClasses = function (element) {
  var scrollState = checkScrollState();
  var el = element[0];

  if (!scrollState.top) {
    el.classList.add(SHADOW_TOP_CLASS);
  } else {
    el.classList.remove(SHADOW_TOP_CLASS);
  }

  if (!scrollState.bottom) {
    el.classList.add(SHADOW_BOTTOM_CLASS);
  } else {
    el.classList.remove(SHADOW_BOTTOM_CLASS);
  }
};

angularModule.directive('scroll',
  function ($window, $timeout) {
    return {
      restrict: 'A',
      link: function ($scope, $element) {
        $timeout(function () {
          updateClasses($element);
        }, 250);

        angular.element($window).bind('scroll', function () {
          updateClasses($element);
        });
      }
    }
  }
);
