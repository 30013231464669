var ContactCtrl = function ($scope,
                            $window,
                            $timeout,
                            $state,
                            $stateParams,
                            BookingResource,
                            PaymentResource,
                            officeResolve,
                            ModalService,
                            SessionService) {
  var self = this;
  this.scope_ = $scope;
  this.scope_.booking = SessionService.getBooking();
  this.scope_.office = officeResolve;
  this.scope_.formSubmitted = false;
  this.scope_.services = SessionService.getTemp().services;
  this.scope_.checks = {
    data: false,
    terms: false,
    covidTerms: false,
    cwa: false,
    cwaSendAnonymous: ''
  };
  this._ModalService = ModalService;
  this._SessionService = SessionService;
  this._BookingResource = BookingResource;
  this._stateParams = $stateParams;
  this._PaymentResource = PaymentResource;
  this._state = $state;
  this._window = $window;
  this.personIndex = -1;
  this.maxGroupSize = 20;
  this.showCWALegal = false;
  this.showPayment = false;

  if (officeResolve.settings.covidTest.enabled) {
    this.scope_.booking.meta = this.scope_.booking.meta || {};
  }

  this.scope_.isGroupBooking = this.scope_.services.some(function (service) {
    if (service.groupBooking && service.groupMaxSize > 1) {
      self.maxGroupSize = Math.min(self.maxGroupSize, service.groupMaxSize)
      return true;
    }
  });
  this.birthday = {
    day: '',
    month: '',
    year: ''
  }
  var customChecks = _.get(this.scope_.office, 'office.settings.widget.appointment.customChecks', []);

  angular.forEach(customChecks, function (customCheck) {
    this.scope_.checks[customCheck.name] = false;
  })

  if (this.scope_.office.settings.covidTest && this.scope_.office.settings.covidTest.cwa && this.scope_.office.settings.covidTest.cwa.enabled) {
    this.showCWALegal = true;
    this.scope_.checks.cwaTerms1 = false;
    this.scope_.checks.cwaTerms2 = false;
  }

  if (this.scope_.isGroupBooking) {
    this.scope_.booking.customerGroup = [] || this.scope_.booking.customerGroup;
  }

  if ($stateParams.channel) {
    this.scope_.booking.channel = $stateParams.channel;
  }

  if (this.scope_.office.settings.payments.mode === 'required') {
    this.scope_.booking.paymentMethod = 'stripe';
  }

  // add resource to fields
  angular.forEach(officeResolve.settings.customFields.customer, function (field) {
    field.resource = 'customer';
  });

  angular.forEach(officeResolve.settings.customFields.appointment, function (field) {
    field.resource = 'appointment';
  });

  this.scope_.customFields = officeResolve.settings.customFields.customer.concat(officeResolve.settings.customFields.appointment);

  this.calcOverallPrice();

  // check if submit button is pressed
  $scope.$on('onSubmitBtnClick', function () {
    self.send();
  });

  // check if submit button is pressed
  $scope.$on('onCheckoutBtnClick', function () {
    self.send(true);
  });

  // load user data from local storage from past visits
  angular.extend(this.scope_.booking.customer, SessionService.getCustomer());
  if (this.scope_.booking.customer &&
    this.scope_.booking.customer.birthday &&
    moment(this.scope_.booking.customer.birthday).isValid()) {

    this.birthday = {
      day: moment(this.scope_.booking.customer.birthday).format('DD'),
      month: moment(this.scope_.booking.customer.birthday).format('MM'),
      year: moment(this.scope_.booking.customer.birthday).format('YYYY')
    }
  }

  if (!this.showBirthdayField() && this.scope_.booking.customer.birthday) {
    delete this.scope_.booking.customer.birthday;
  }
};

ContactCtrl.prototype.showAddressFields = function () {
  var customerAddressSettings = _.get(this.scope_, 'office.settings.widget.appointment.customerAddress');
  return customerAddressSettings && ['optional', 'required'].indexOf(customerAddressSettings) > -1;
};

ContactCtrl.prototype.addressFieldsRequired = function () {
  var customerAddressSettings = _.get(this.scope_, 'office.settings.widget.appointment.customerAddress');
  return customerAddressSettings && customerAddressSettings === 'required';
};

ContactCtrl.prototype.showBirthdayField = function () {
  var customerBirthdaySettings = _.get(this.scope_, 'office.settings.widget.appointment.customerBirthday');
  return customerBirthdaySettings && ['optional', 'required'].indexOf(customerBirthdaySettings) > -1;
};

ContactCtrl.prototype.birthdayFieldRequired = function () {
  var customerBirthdaySettings = _.get(this.scope_, 'office.settings.widget.appointment.customerBirthday');
  return customerBirthdaySettings && customerBirthdaySettings === 'required';
};


/**
 * sum all service prices
 */
ContactCtrl.prototype.calcOverallPrice = function () {
  var self = this;
  this.scope_.overallPrice = 0;
  this.scope_.priceVar = false;

  angular.forEach(this.scope_.services, function (service) {
    service.price = service.price || 0;
    self.scope_.overallPrice += parseFloat(service.price);

    if (service.priceVar) {
      self.scope_.priceVar = true;
    }
  });

  this.scope_.$emit('price-changed', {
    price: self.scope_.overallPrice,
    currency: _.get(this.scope_, 'office.settings.currency')
  });
};

ContactCtrl.prototype.validateForm = function () {
  var valid = false;
  var self = this;
  this.scope_.formSubmitted = true;

  if(this.showBirthdayField() && this.dayInput[0]) {
    this.dayInput[0].classList.remove('date-invalid');
    this.monthInput[0].classList.remove('date-invalid');
    this.yearInput[0].classList.remove('date-invalid');
  }

  if (!this.scope_.BookingContactForm.$valid) {
    var invalidElements = document.querySelector('form .ng-invalid');

    if (invalidElements && invalidElements.scrollIntoView) {
      invalidElements.scrollIntoView({ block: 'start', behavior: 'smooth' });
    }
  } else if (!this.scope_.checks.data ||
    (this.scope_.office.settings.conditionsEnabled && !this.scope_.checks.terms) ||
    (this.scope_.office.settings.covidTest.covidPrivacyTermsEnabled && !this.scope_.checks.covidTerms)) {
    window.scrollTo({ left: 0, top: document.body.scrollHeight, behavior: 'smooth' });
  } else if(this.showBirthdayField() &&
    this.birthdayFieldRequired() &&
    this.dayInput[0] &&
    !moment(`${this.birthday.year}-${this.birthday.month}-${this.birthday.day}`).isValid()) {
    this.dayInput[0].classList.add('date-invalid');
    this.monthInput[0].classList.add('date-invalid');
    this.yearInput[0].classList.add('date-invalid');
    var invalidElements = document.querySelector('form .date-invalid');

    if (invalidElements && invalidElements.scrollIntoView) {
      invalidElements.scrollIntoView({ block: 'start', behavior: 'smooth' });
    }
  } else {
    this.scope_.$emit('contact-form-valid', true);
    valid = true;
  }

  return valid;
};

/**
 *
 */
ContactCtrl.prototype.send = function (checkout) {
  var self = this;
  var valid = this.validateForm();
  var method = checkout ? 'createWithCheckout' : 'create';

  if (valid) {
    self.scope_.$emit('contact-form-loading', true);

    var customer = angular.copy(this.scope_.booking.customer);

    if (self.showBirthdayField() &&
      this.birthday.day &&
      this.birthday.month &&
      this.birthday.year &&
      moment(`${this.birthday.year}-${this.birthday.month}-${this.birthday.day}`).isValid()) {
      customer.birthday = moment(`${this.birthday.year}-${this.birthday.month}-${this.birthday.day}`).hours(12).toDate();
    }

    this._BookingResource[method]({
      slug: this._stateParams.officeSlug,
      slot: this.scope_.booking.slot,
      customer: customer,
      customerGroup: this.scope_.booking.customerGroup,
      customFields: this.scope_.booking.customFields,
      channel: this.scope_.booking.channel,
      checks: this.scope_.checks,
      meta: this.scope_.booking.meta
    }).then(function (response) {
      // store user data in local storage for future visits
      if (checkout && response.data && response.data.secret) {
        self._SessionService.setStripeSecret(response.data.secret);
      }

      self._SessionService.setCustomer(customer);

      self.scope_.$emit('contact-form-loading', false);
      if (response && response.status === 200 && response.data.success) {
        if (parent) {
          parent.postMessage({ type: 'bookingCreated' }, '*');
        }

        if (checkout) {
          self._state.go('booking.payment');
        } else if (self.scope_.office &&
          self.scope_.office.settings &&
          self.scope_.office.settings.redirectAfterBooking &&
          self.scope_.office.settings.redirectUrl &&
          self.scope_.booking.channel !== 'termin2go') {
          try {
            self._window.location.href = self.scope_.office.settings.redirectUrl;
          } catch (e) {
            console.error(e);
          }
        } else {
          self._state.go('booking.success');
        }

      } else {
        self.scope_.$emit('contact-form-send', false);
      }
    }).catch(function (error) {
      self.scope_.$emit('contact-form-loading', false);
      self.scope_.$emit('contact-form-send', false);
      if (error && error.data && error.data.error === 'collided') {
        self.collidedError = true;
        self.scope_.$emit('contact-form-send', true);
      } else {
        console.error(error);
      }
    });
  }
};


/**
 *
 */
ContactCtrl.prototype.openLegalModal = function (path, title) {
  var self = this;

  this._ModalService.open({
    template: templateUrl + '/views/TermsModalView.html',
    controller: 'TermsModalCtrl as TermsModalCtrl',
    resolve: {
      officeResolve: self.scope_.office,
      path: function () {
        return path
      },
      title: function () {
        return title
      }
    }
  });
};

ContactCtrl.prototype.getDataPrivacyLink = function () {
  var settings = this.scope_.office.settings;

  if (settings.ownDataPrivacyTerms && settings.dataPrivacyTermsLink) {
    return settings.dataPrivacyTermsLink;
  } else {
    return 'https://www.termin2go.com/datenschutz'
  }
};

ContactCtrl.prototype.getPrivacyHosterName = function () {
  var settings = this.scope_.office.settings;

  if (settings.ownDataPrivacyTerms && settings.dataPrivacyTermsLink) {
    return this.scope_.office.name;
  } else {
    return 'Termin2go';
  }
};

/**
 * open modal with service details
 * @param {Object} service
 */
ContactCtrl.prototype.showPersonModal = function (person) {
  var self = this;

  var modalInstance = this._ModalService.open({
    template: templateUrl + '/views/PersonModalView.html',
    controller: 'PersonModalCtrl as PersonModalCtrl',
    resolve: {
      personResolve: person,
      officeResolve: self.scope_.office
    }
  });

  modalInstance.closed.then(function (personData) {
    if (personData) {
      if (self.personIndex === -1) {
        self.scope_.booking.customerGroup.push(personData);
      } else {
        self.scope_.booking.customerGroup[self.personIndex] = personData;
      }
    }
  });
};

ContactCtrl.prototype.addPerson = function () {
  this.showPersonModal({});
  this.personIndex = -1;
}

ContactCtrl.prototype.editPerson = function (person, index) {
  this.showPersonModal(person);
  this.personIndex = index;
}

ContactCtrl.prototype.removePerson = function (index) {
  this.scope_.booking.customerGroup.splice(index, 1)
};

ContactCtrl.prototype.onDayInput = function () {
  if (this.birthday.day && this.birthday.day.length === 2) {
    this.monthInput[0].focus();
  }
};

ContactCtrl.prototype.onMontInput = function () {
  if (!this.birthday.month && this.birthday.day) {
    this.dayInput[0].focus();
    this.dayInput[0].setSelectionRange(this.birthday.day.length, this.birthday.day.length);
  }

  if (this.birthday.month && this.birthday.month.length === 2) {
    this.yearInput[0].focus();
  }
};

ContactCtrl.prototype.onYearInput = function () {
  if (!this.birthday.year && this.birthday.month) {
    this.monthInput[0].focus();
    this.monthInput[0].setSelectionRange(this.birthday.month.length, this.birthday.month.length);
  }
};

ContactCtrl.prototype.getAddresses = function () {
  const addresses = [];
  let ownAdress = false;
  let customerOnSide = false;

  this.scope_.services.forEach((service) => {
    if (service.location) {
      const serviceLocation = this.scope_.office.serviceLocations.find(serviceLocation => serviceLocation._id === service.location);

      if (serviceLocation) {
        if (serviceLocation.type === 'at-customer') {
          customerOnSide = true;
        } else if (serviceLocation.ownAddress) {
          ownAdress = true;
        } else if (serviceLocation.type === 'remote' && serviceLocation.label) {
          addresses.push(serviceLocation.label);
        } else if (serviceLocation.type === 'address' && serviceLocation.label) {
          addresses.push(serviceLocation.label);
        }
      } else {
        ownAdress = true;
      }
    } else {
      ownAdress = true;
    }
  });

  if (ownAdress) {
    addresses.push(this.scope_.office.address.formatted);
  }

  if (customerOnSide) {
    addresses.push('Vor-Ort');
  }

  return addresses;
}

module.exports = ContactCtrl;
