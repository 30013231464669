var angularModule = angular.module('t2g.booking.directive.collapseItem', []);
var COLLAPSED_CLASS = 'collapsed';

module.exports = angularModule;

angularModule.directive('collapseItem',
  function () {
    return {
      restrict: 'A',
      require: '^collapseGroup',
      link: function ($scope, $element, $attributes, collapseGroup) {
        var inited = false;

        if ($attributes['collapseItem'] === 'true') {
          $scope.collapsed = true;
        } else {
          $scope.collapsed = false;
        }

        $scope.hide = function () {
          $element.addClass(COLLAPSED_CLASS);
          $scope.collapsed = true;
        };

        $scope.show = function () {
          $element.removeClass(COLLAPSED_CLASS);

          if (collapseGroup) {
            collapseGroup.closeOther($scope);
          }
        };

        var watcher = $scope.$watch('collapsed', function (newValue) {
          if (inited) {
            if (newValue) {
              $scope.hide();
            } else {
              $scope.show();
            }
          }

          inited = true;
        });

        $scope.$on('$destroy', function () {
          watcher();
        });

        if (collapseGroup) {
          collapseGroup.addCollapseItem($scope);
        }
      }
    }
  }
);
