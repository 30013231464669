var angularModule = angular.module('t2g.booking.directive.collapseGroup', []);
module.exports = angularModule;

angularModule.directive('collapseGroup',
  function () {
    return {
      controller: function () {
        var collapseItems = [];
        var itemsLen = 0;

        this.addCollapseItem = function (item) {
          collapseItems.push(item);
          itemsLen++;
        };

        this.closeOther = function (item) {
          var itemIndex = collapseItems.indexOf(item);

          for (var i = 0; i < itemsLen; i++) {
            var currItem = collapseItems[i];

            if (i != itemIndex) {
              currItem.hide();
            }
          }
        };
      }
    }
  }
);
