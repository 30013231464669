var ServiceCtrl = function ($stateParams,
                            $state,
                            $timeout,
                            ModalService,
                            officeResolve,
                            SessionService) {
  this._model = {
    office: officeResolve
  };

  this._timeout = $timeout;
  this._state = $state;
  this._ModalService = ModalService;
  this._SessionService = SessionService;
  this._booking = this._SessionService.getBooking();
  this._temp = this._SessionService.getTemp();
  this._services = this._temp.services;
  this._widgetSettings = _.get(this._model.office, 'settings.widget.appointment', {});

  if ($stateParams.channel) {
    this._booking.channel = $stateParams.channel;
  }

  if (parent) {
    parent.postMessage({type: 'heightChanged', height: document.documentElement.scrollHeight}, '*');
  }

  this._preselectService($stateParams);
};


/**
 * select a service
 * @param {Object} service
 */
ServiceCtrl.prototype.select = function (service) {
  var serviceIndex = -1;

  for (var i = 0, serviceLen = this._services.length; i < serviceLen; i++) {
    var bookedService = this._services[i];

    if (bookedService._id === service._id) {
      serviceIndex = i;
      break;
    }
  }

  // deselect on second select (e,g. click twice)
  if (serviceIndex === -1) {
    var maxLength = _.get(this._model.office, 'settings.maxServices', 1);

    if (this._services.length === maxLength) {
      this._services.shift();
    }

    this._services.push(service);
  } else {
    this._services.splice(serviceIndex, 1);
  }

  // reset current selected slot
  this._temp.combination = null;
  this._booking.slot = null;
};


/**
 * check if service is selected
 * @param {Object} service
 * @return {Boolean}
 */
ServiceCtrl.prototype.isSelected = function (service) {
  var services = this._services;

  for (var i = 0, serviceLen = services.length; i < serviceLen; i++) {
    if (services[i]._id === service._id) {
      return true;
    }
  }
  return false;
};


/**
 * open modal with service details
 * @param {Object} service
 */
ServiceCtrl.prototype.showServiceDetail = function (service) {
  var self = this;

  var modalInstance = this._ModalService.open({
    template: templateUrl + '/views/ServiceDetailModalView.html',
    controller: 'ServiceDetailModalCtrl as ServiceDetailModalCtrl',
    resolve: {
      serviceResolve: service,
      isSelected: self.isSelected(service),
      officeResolve: self._model.office
    }
  });

  modalInstance.closed.then(function (pickedService) {
    if (pickedService) {
      self.select(pickedService);
    }
  });
};


/**
 * @param {String} serviceId
 * @returns {Object}
 * @private
 */
ServiceCtrl.prototype._getService = function (serviceId) {
  var service;
  var groupIndex = -1;

  _.forEach(this._model.office.serviceGroups, function (serviceGroup, index) {
    service = _.find(serviceGroup.services, {_id: serviceId});
    groupIndex = index;

    if (service) {
      return false;
    }
  });

  return {
    groupIndex: groupIndex,
    service: service
  };
};


/**
 * @param {Object} queryParams
 * @private
 */
ServiceCtrl.prototype._preselectService = function (queryParams) {
  var self = this;
  var serviceId = _.get(queryParams, 'service');
  var auto = _.get(queryParams, 'auto');
  var service;

  if (serviceId) {
    var serviceLookUp = this._getService(serviceId);
    service = serviceLookUp.service;
    this.openedGroupIndex = serviceLookUp.groupIndex;

    if (service) {
      this.select(service);
      //
      // if(auto) {
      //   self._timeout(function() {
      //     self._state.go('appointments');
      //   });
      // }
    }
  }
};


/**
 * @param {Number} index
 * @param {Number} first
 * @returns {Boolean}
 */
ServiceCtrl.prototype.isGroupCollapsed = function (index, first) {
  var serviceGroupCollapsing = _.get(this._widgetSettings, 'serviceGroupCollapsing', 0);

  // 1 = all expanded
  if (this._widgetSettings.serviceGroupCollapsing === 1) {
    return false;
    // 2 = all collapsed
  } else if (this._widgetSettings.serviceGroupCollapsing === 2) {
    return true;
    // 0 = default (all collapsed except first)
  } else if (this.openedGroupIndex > -1) {
    if (this.openedGroupIndex !== index) {
      return true;
    } else {
      return false;
    }
  } else if (!first) {
    return true;
  } else {
    return false;
  }
};

module.exports = ServiceCtrl;
