var angularModule = angular.module('t2g.booking.directive.numberInput', []);

module.exports = angularModule;

angularModule.directive('numberInput', function () {
    return {
        restrict: 'A',
        require: 'ngModel',
        link: function ($scope, $element, $attributes, $ngModelController) {

            $ngModelController.$parsers.push(function (inputValue) {
                var transformedInput = inputValue.replace(/[^0-9]+/g, '');

                if (transformedInput != inputValue) {
                    $ngModelController.$setViewValue(transformedInput);
                    $ngModelController.$render();
                }

                return transformedInput;
            });

        }
    }
});



