var angularModule = angular.module('t2g.booking.directive.dateMask', []);

module.exports = angularModule;

angularModule.directive('dateMask',
  function () {
    return {
      restrict: 'A',
      require: 'ngModel',
      compile: function (element, attributes) {
        return function (scope, element, attributes, ngModel) {
          const start = moment('1920-01-01');
          const end = moment().subtract(6, 'month');
          ngModel.$validators.dateMask = function (value) {
            return moment(value, 'DD.MM.YYYY').isValid() &&
              moment(value, 'DD.MM.YYYY').isAfter(start) &&
              moment(value, 'DD.MM.YYYY').isBefore(end)
          }
        }
      }
    }
  }
);
