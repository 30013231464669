var angularModule = angular.module('t2g.booking.directive.collapse', []);
module.exports = angularModule;

angularModule.directive('collapse',
  function ($timeout) {
    return {
      restrict: 'A',
      link: function ($scope, $element, $attributes) {
        $attributes.$observe('collapse', function (value) {
          var height = 0;

          if (value === 'false') {
            $timeout(function () {
              height = $element[0].scrollHeight + 'px';
              $element.css('height', height);
            });
          } else {
            height = 0 + 'px';
            $element.css('height', height);
          }
        });
      }
    }
  }
);
