var angularModule = angular.module('t2g.booking.directive.dropdownControl', []);

module.exports = angularModule;

angularModule.directive('dropdownControl',
  function () {
    return {
      restrict: 'A',
      link: function ($scope, $element) {
        $element.on('click', function (event) {
          $scope.$apply(function () {
            $scope.opened = !$scope.opened;
          });

          event.stopPropagation();
        });
      }
    }
  }
);
