'use strict';

var angularModule = angular.module('t2g.booking.filter.momentFilter', []);

module.exports = angularModule;

angularModule.filter('moment', function () {
  return function (date, format) {
    return moment(date).format(format);
  };
});

angularModule.filter('time', function () {
  return function (time) {
    var hours = Math.floor(time / 60);
    var minutes = time % 60;

    return ('0' + hours).slice(-2) + ":" + ('0' + minutes).slice(-2);
  }
});
