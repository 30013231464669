var ServiceDetailModalCtrl = function ($scope,
                                       isSelected,
                                       modalInstance,
                                       officeResolve,
                                       serviceResolve) {
  $scope.currentService = serviceResolve;
  $scope.isSelected = isSelected;
  $scope.duration = serviceResolve.duration;

  this.scope_ = $scope;
  this.modalInstance_ = modalInstance;
  this.office = officeResolve;
};

ServiceDetailModalCtrl.prototype.close = function (pickCurrentService) {
  if (pickCurrentService) {
    this.modalInstance_.close(this.scope_.currentService);
  } else {
    this.modalInstance_.close();
  }
};

module.exports = ServiceDetailModalCtrl;
