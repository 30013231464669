var angularModule = angular.module('t2g.booking.services.session', []);

module.exports = angularModule;

angularModule.factory('SessionService', function ($sessionStorage, $localStorage) {
  var $storage = $sessionStorage.$default({
    booking: {
      customer: {},
      overallPrice: 0,
      paymentMethod: 'on-site',
      slot: null,
      meta: {}
    },
    office: null,
    temp: {
      services: [],
      payment: {
        stripeSecret: ''
      }
    }
  });

  var $localStorage = $localStorage.$default({
    customer: {
      firstname: null,
      lastname: null,
      telephone: null,
      email: null
    }
  });

  return {
    getBooking: function () {
      return $storage.booking;
    },
    getOffice: function () {
      return $storage.office;
    },
    setOffice: function (office) {
      var serviceIds = {};
      $storage.office = office;
      $storage.temp.services = $storage.temp.services || [];

      _.forEach($storage.office.serviceGroups, function (serviceGroup) {
        _.forEach(serviceGroup.services, function (service) {
          serviceIds[service._id] = true;
        })
      });

      $storage.temp.services = _.filter($storage.temp.services, function (service) {
        return serviceIds[service._id];
      });
    },
    getTemp: function () {
      return $storage.temp;
    },
    getCustomer: function () {
      return $localStorage.customer;
    },
    getPayment: function () {
      return $storage.temp.payment;
    },
    setCustomer: function (customer) {
      $localStorage.customer = customer;
    },
    setServices: function (services) {
      $storage.temp.services = services;
    },
    setStripeSecret: function (secret) {
      $storage.temp.payment = $storage.temp.payment || {};
      $storage.temp.payment.stripeSecret = secret;
    },
    addService: function (service) {
      $storage.temp.services = $storage.temp.services || [];

      var isAlreadyPicked = _.find($storage.temp.services, {_id: service._id});

      if (!isAlreadyPicked) {
        $storage.temp.services.push(service);
      }
    },
    resetBooking: function () {
      $storage.temp = {
        services: []
      };

      $storage.booking = {
        customer: {},
        overallPrice: 0,
        slot: null,
        paymentMethod: 'on-site'
      }
    }
  }
});
