var angularModule = angular.module('t2g.booking.service.mediaQuery', []);

module.exports = angularModule;

angularModule.factory('MediaQueryService', function () {
  return {
    matches: function (width) {
      var mq = window.matchMedia('(max-width: ' + width + 'px)');

      return mq.matches;
    },
    isMobile: function () {
      return this.matches(350);
    }
  }
});
