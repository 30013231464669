var angularModule = angular.module('t2g.booking.directive.validationEquals', []);

module.exports = angularModule;

angularModule.directive('validationEquals',
  function () {
    return {
      restrict: 'A',
      require: 'ngModel',
      compile: function (element, attributes) {
        return function (scope, element, attributes, ngModel) {
          ngModel.$validators.equals = function (value) {
            if (attributes.validationEquals) {
              return value === attributes.validationEquals
            } else {
              return true;
            }
            return true;
          }
        }
      }
    }
  }
);
