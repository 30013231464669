var angularModule = angular.module('t2g.booking.directive.outsideClick', []);

module.exports = angularModule;

angularModule.directive('outsideClick',
  function ($document) {
    return {
      restrict: 'A',
      link: function ($scope, $element, $attributes) {
        var expression = $attributes['outsideClick'];

        $document.on('click', function () {
          $scope.$eval(expression);
          $scope.$apply();
        });
      }
    }
  }
);
