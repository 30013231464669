var angularModule = angular.module('t2g.booking.directive.focusFirstError', []);

module.exports = angularModule;

angularModule.directive('focusFirstError',
  function ($timeout) {
    return {
      restrict: 'A',
      link: function ($scope, $element) {
        $scope.$on('onSubmitBtnClick', function () {
          $timeout(function () {
            var firstErrorField = document.querySelectorAll('.ng-invalid,.check-invalid');

            if (firstErrorField && firstErrorField.length) {
              firstErrorField[0].focus();
            }
          });
        });

      }
    }
  }
);
